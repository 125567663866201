<template>
    <slot />
    <v-snackbar multi-line v-model="isVisible" :timeout="timeout" :color="color">
        {{ text }}

        <template v-slot:actions>
            <v-btn icon="mdi-close" @click="isVisible = false">
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
type ToastData = {
    text: String,
    color: String,
};

export default {
    data: () => ({
        isVisible: false,
        text: '',
        color: 'info',
        timeout: 6000,


        events: [] as Array<ToastData>,
    }),
    provide() {
        return {
            scheduleToast: this.scheduleToast,
        }
    },
    methods: {
        scheduleToast(text: string, color: string = "info") {
            this.events.push({
                text: text,
                color: color
            });
            // Force re-checking of the state
            // so that if there is no queue -
            // current toast will be shown.
            !this.isVisible && this.toastStateChanged(true);
        },
        toastStateChanged(noTimeout = false) {
            const showToast = () => {
                if (this.isVisible || this.events.length === 0) {
                    return;
                }

                const nextEvent = this.events.splice(0, 1)[0] as ToastData;

                this.text = nextEvent.text;
                this.color = nextEvent.color;
                this.isVisible = true;
            }

            let timeout = 1000;
            if (noTimeout) {
                timeout = 0;
            }

            setTimeout(showToast, timeout);
        }
    },
    watch: {
        isVisible() {
            this.toastStateChanged();
        }
    }
}
</script>